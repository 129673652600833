<template>
  <div id="commonPagnation" >
    <div class="slot-wrapper">
      <slot name="left"></slot>
    </div>
    <a-pagination
      background
      layout="prev, pager, next"
      class="pull-right"
      :current="pageNum"
      :default-current="pageNum"
      :page-size="pageSize"
      :total="totalNum"
      size="mini"
      show-jumper
      :base-size="0"
      :buffer-size="1"
      show-page-size
      :page-size-options='[2, 10, 15, 20, 50, 100]'
      @change="changePage"
      @page-size-change="changeSize">
    </a-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageNum: Number,
    pageSize: Number,
    totalNum: Number,
    placement: String // 默认居中；也可以设置等于right
  },
  methods: {
    changePage(page) {
      // this.$parent.pageNum = page;
      // this.$parent.showPage();
      this.$emit('pageChange', page)
    },
    changeSize(size) {
      // this.$parent.pageNum = 1;
      // this.$parent.pageSize = size;
      // this.$parent.showPage();
      this.$emit('sizeChange', size)
    }
  }
}
</script>

<style lang='scss'>
  #commonPagnation {
    padding: 20px 0 0 10px;
    display: flex;
    justify-content: space-between;

    .arco-pagination-size-mini .arco-pagination-total {
      line-height: 32px;
    }

    .arco-pagination-size-mini .arco-pagination-item {
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      background: #f4f4f5;
    }
    .arco-pagination-item:not(:last-child) {
      margin-right: 10px;
    }
    .arco-pagination-item-active, 
    .arco-pagination-item-active:hover,
    .arco-pagination-item:hover {
      background: #2D69DB!important;
      color: #fff;
    }

    .arco-pagination-jumper,
    .arco-pagination-jumper .arco-pagination-jumper-input {
      height: 30px;
      line-height: 30px;
    }

    .arco-pagination-options,
    .arco-pagination-options .arco-select {
      height: 30px;
      line-height: 30px;
      width: 100px;
    }

    .slot-wrapper {
      display: flex;
      align-items: center;
      
      .arco-btn {
        margin-right: 8px;
        border-radius: var(--border-radius-medium);
      }

      .arco-checkbox {
        margin-right: 20px;
      }
    }
  }
</style>
