

function formatDate(date:Date) {
  const year = date.getFullYear();
  var month= date.getMonth() + 1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
  var day=date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  var hours=date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  var minutes=date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  var seconds=date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  var week = date.getDay().toString();

  switch(week) {
    case '1': 
    week = '一';
    break;
    case '2': 
    week = '二';
    break;
    case '3': 
    week = '三';
    break;
    case '4': 
    week = '四';
    break;
    case '5': 
    week = '五';
    break;
    case '6': 
    week = '六';
    break;
    default:
    week = '日'
  }

  return `${ year }年${ month }月${ day }日 星期${ week }`;
}

function treeEvent(dataList: any = [], child: any | null = true) {
  let data:[] = [] as any, no:[] = [];
  dataList.forEach((item: any) => {
    if(item.parentId == 0) {
      (data as any).push({ 
        ...item,
        title: item.classificationName || item.cateName,
        id: item.id || item.cateId,   
        key: item.id || item.cateId,  
        children: [],
        parentId: 0,
        // upNumber: item.upNumber,
        // downNumber: item.downNumber,
        isAllow: item.isAllow,
        // classificationName: item.classificationName,
        // parentClassification: item.parentClassification
      })
    }else {
      (no as any).push({ 
        ...item,
        title: item.classificationName || item.cateName,
        // classificationName: item.classificationName,
        id: item.id || item.cateId,  
        key: item.id || item.cateId,  
        // parentId: item.parentId,
        // upNumber: item.upNumber,
        // downNumber: item.downNumber,
        isAllow: item.isAllow,
        // parentClassification: item.parentClassification
      })
    }
  });
  data.forEach((item: any, index: any) => {
    aa(item.id, data, index)
  })
  function aa(id: any, O: any, index: any) {
    let c = no.filter((item: any) => item.parentId == id)
    if(c.length > 0) {
      O[index].children = c;
      c.forEach((item1: any, index1: any) => {
        item1.parentName = O[index].title
        aa(item1.id, O[index].children, index1)
      })
    }else {
      O[index] = { 
        ...O[index],
        // title: O[index].title,
        id: O[index].id || O[index].cateId,  
        key: O[index].id || O[index].cateId,  
        // parentId: O[index].parentId,
        // upNumber: O[index].upNumber,
        // downNumber: O[index].downNumber,
        // classificationName: O[index].classificationName,
        isAllow: O[index].isAllow,
        // parentClassification: O[index].parentClassification
      }
      
    }
  }
  return data as [];
}

export { formatDate, treeEvent  }